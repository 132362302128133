/* eslint-disable no-unused-vars */
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useState } from "react";
import React from "react";
import useTimeout from "./useTimeout";
import useGenerator from "./useGenerator";
import Win from "./win";
import { grading, time } from "./common";

let signs = ["+", "-"];

const Easy = () => {
  //* DATA
  const [data, setData] = useState({
    num1: Math.ceil(Math.random() * 50),
    num2: Math.ceil(Math.random() * 50),
    sign: signs[Math.ceil(Math.random() * signs.length) - 1],
    score: 0,
    incorrect: null,
    response: "",
    message: ""
  });

  //* TIMEOUT SYSTEM
  const allowed = useTimeout(time);

  //! Functions
  let inputChange = (event) => {
    setData({
      ...data,
      response: `${event.target.value}`
    });
  };

  let onSubmit = (event) => {
    let answer = parseInt(data.response);
    if (event.type === "click" || event.key === "Enter") {
      useGenerator(answer, signs, data, setData, false);
    }
  };
  //* PAGE
  return (
    <div className="easy">
      <h2>Easy difficulty</h2>
      {!allowed && (
        <div className="questions">
          <div className="block problem">
            <MathJaxContext>
              <MathJax
                renderMode="pre"
                typesettingOptions={{ fn: "tex2chtml" }}
                text={`\\LARGE{${data.num1}${data.sign}${data.num2}}`}
                inline
                dynamic
              />
            </MathJaxContext>
          </div>

          <div className="field">
            <div className="box in-box">
              {data.message !== "" && (
                <div
                  className={`box ${
                    data.incorrect !== null
                      ? data.incorrect
                        ? grading[0]
                        : grading[1]
                      : " "
                  }`}
                >
                  {data.message}
                </div>
              )}
              <p className="control has-icons-left">
                <input
                  type="text"
                  className="input is-primary in"
                  onChange={inputChange}
                  onKeyPress={onSubmit}
                  value={data.response}
                />
                <span className="icon is-small is-left in-icon">
                  <i className="fa fa-solid fa-question" />
                </span>
              </p>
              <button
                className="button is-rounded"
                id="submit"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>

          <div className="score">Score: {data.score}</div>
        </div>
      )}

      {allowed && <Win data={data} />}
    </div>
  );
};

export default Easy;
/*
if (!answer) {
  setData({
    ...data,
    message: "Only numbers are allowed in the input field"
  });
} else if (
  evaluate(`${data.num1} ${data.sign} ${data.num2}`) === answer
) {
  setData({
    ...data,
    score: data.score + 1,
    num1: Math.ceil(Math.random() * 50),
    num2: Math.ceil(Math.random() * 50),
    sign: signs[Math.ceil(Math.random() * signs.length) - 1],
    response: "",
    message: "Correct",
    incorrect: false
  });
} else {
  setData({
    ...data,
    score: data.score === 0 ? data.score : data.score - 1,
    message: "Incorrect",
    incorrect: true
  });
}

*/
