/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";



const useTimeout = (timeout) => {
  const [allowed, setAllowed] = useState(false);

  setTimeout(() => {
    setAllowed(true);
  }, timeout);

  if (allowed) return allowed;
};

export default useTimeout;
