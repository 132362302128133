import React from "react";
import useGenerator from "./useGenerator";
import useTimeout from "./useTimeout";
import Win from "./win";
import { grading, time } from "./common";
const { MathJaxContext, MathJax } = require("better-react-mathjax");
const { useState } = require("react");
const signs = ["+", "-", "*", "/"];

const Hard = () => {
  const [data, setData] = useState({
    num1: Math.ceil(Math.random() * 50),
    num2: Math.ceil(Math.random() * 50),
    num3: Math.ceil(Math.random() * 5),
    num4: Math.ceil(Math.random() * 5),
    sign1: signs[Math.ceil(Math.random() * signs.length) - 1],
    sign2: signs[Math.ceil(Math.random() * signs.length) - 1],
    incorrect: null,
    response: "",
    message: "",
    score: 0
  });

  const allowed = useTimeout(time);
  // TODO use OnChange Function
  const inputChange = (event) => {
    setData({ ...data, response: event.target.value });
  };
  // TODO use OnSubmit function
  const onSubmit = (event) => {
    let answer = parseFloat(data.response);

    if (event.type === "click" || event.key === "Enter")
      useGenerator(answer, signs, data, setData, true);
  };
  const generateEquation = () => {
    let equation = ``;
    let num1 = data.num1;
    let num2 = data.num2;
    let num3 = data.num3;
    let num4 = data.num4;
    let sign1 = data.sign1;
    let sign2 = data.sign2;

    if (sign1 === sign2) {
      if (sign1 === "/") {
        equation = `\\frac{${num1}}{${num2}(${num3}^{${num4}})}`;
      } else if (sign1 === "*") {
        equation = `${num1}\\times${num2}\\times${num3}^{${num4}}`;
      } else {
        equation = `${num1} ${sign1} ${num2} ${sign2} ${num3}^{${num4}}`;
      }
    } else {
      if (sign1 === "/") {
        if (sign2 === "*") {
          equation = `(\\frac{${num1}}{${num2}})\\times${num3}^{${num4}}`;
        } else {
          equation = `(\\frac{${num1}}{${num2}}) ${sign2} ${num3}^{${num4}}`;
        }
      } else if (sign1 === "*") {
        if (sign2 === "/") {
          equation = `\\frac{${num1}\\times${num2}}{${num3}^{${num4}}}`;
        } else {
          equation = `${num1}\\times${num2} ${sign2} ${num3}^{${num4}}`;
        }
      } else {
        if (sign2 === "/") {
          equation = `${num1} ${sign1} \\frac{${num2}}{${num3}^{${num4}}}`;
        } else if (sign2 === "*") {
          equation = `${num1} ${sign1} ${num2}\\times ${num3}^{${num4}}`;
        } else {
          equation = `${num1} ${sign1} ${num2} ${sign2} ${num3}`;
        }
      }
    }

    return equation;
  };
  //   TODO complete the page
  return (
    <div className="hard">
      <h2>Hard Difficulty</h2>
      {!allowed && (
        <div className="questions">
          <div className="block problem">
            <MathJaxContext>
              <MathJax
                renderMode="pre"
                typesettingOptions={{ fn: "tex2chtml" }}
                text={`\\Huge{${generateEquation()}}`}
                inline
                dynamic
              />
            </MathJaxContext>
          </div>

          <div className="field ">
            <p>Round the number to the nearest decimal point</p>
            <div className="box in-box block">
              {data.message !== "" && (
                <div
                  className={`box ${
                    data.incorrect !== null
                      ? data.incorrect
                        ? grading[0]
                        : grading[1]
                      : " "
                  }`}
                >
                  {data.message}
                </div>
              )}
              <p className="control has-icons-left">
                <input
                  type="text"
                  className="input is-primary in"
                  value={data.response}
                  onChange={inputChange}
                  onKeyDown={onSubmit}
                />
                <span className="icon is-small is-left in-icon">
                  <i className="fa fa-solid fa-question" />
                </span>
              </p>

              <button
                className="button is-rounded"
                id="submit"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="score">Score: {data.score}</div>
        </div>
      )}

      {allowed && <Win data={data} />}
    </div>
  );
};

export default Hard;
//!__________________________________________________________
// [data.sign1, data.sign2].forEach((sign) => {

//   if (sign === "/") {
//     if (n === 0) {
//       add = `\\frac{${data.num1}}{${data.num2}}`;
//       //   temp = add;
//     } else {
//       add = `\\frac{${add}}{${data.num3}^{${data.num4}}}`;
//     }
//   } else if (sign === "*") {
//     if (n === 0) {
//       add = `${data.num1}\\times${data.num2}`;
//     } else {
//       add = `${data.sign1}\\times${data.num3}^{${data.num4}}`;
//     }
//   } else {
//     if (n === 0) {
//       add = `${data.num1} ${data.sign0} ${data.num2}`;
//     } else {
//       add = `${data.sign1} ${data.num3}^{${data.num4}}`;
//     }
//   }

//   n++;
//   equation = equation + add
// });
//!__________________________________________________________
// for (let i = 0; i < signsUsed.length; i++) {
//   if (sign === "/") {
//     if (i === 0) {
//       add = `\\frac{${data.num1}}{${data.num2}}`;
//       //   temp = add;
//     } else {
//       add = `\\frac{${add}}{${data.num3}^{${data.num4}}}`;
//     }
//   } else if (sign === "*") {
//     if (i === 0) {
//       add = `${data.num1}\\times${data.num2}`;
//     } else {
//       add = `${data.sign1}\\times${data.num3}^{${data.num4}}`;
//     }
//   } else {
//     if (i === 0) {
//       add = `${data.num1} ${data.sign0} ${data.num2}`;
//     } else {
//       add = `${data.sign1} ${data.num3}^{${data.num4}}`;
//     }
//   }
//   equation = equation + add;
// }
//!__________________________________________________________
