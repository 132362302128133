import { evaluate } from "mathjs";

const useGenerator = (answer, signs, data, setData, hard) => {
  if (!hard) {
    if (!answer && answer !== 0) {
      setData({
        ...data,
        message: "Only numbers are allowed in the input field",
        incorrect: null
      });
    } else if (
      Number(evaluate(`${data.num1} ${data.sign} ${data.num2}`).toFixed(1)) ===
      answer
    ) {
      setData({
        ...data,
        score: data.score + 1,
        num1: Math.ceil(Math.random() * 50),
        num2: Math.ceil(Math.random() * 50),
        sign: signs[Math.ceil(Math.random() * signs.length) - 1],
        response: "",
        message: "Correct",
        incorrect: false
      });
    } else {
      setData({
        ...data,
        score: data.score === 0 ? data.score : data.score - 1,
        message: "Incorrect",
        incorrect: true
      });
    }
  } else {
    if (!answer && answer !== 0) {
      setData({
        ...data,
        message: "Only numbers are allowed in the input field",
        incorrect: null
      });
    } else if (
      Number(
        evaluate(
          `${data.num1} ${data.sign1} ${data.num2} ${data.sign2} ${data.num3}^${data.num4}`
        ).toFixed(1)
      ) === answer
    ) {
      setData({
        ...data,
        score: data.score + 1,
        num1: Math.ceil(Math.random() * 50),
        num2: Math.ceil(Math.random() * 50),
        num3: Math.ceil(Math.random() * 5),
        num4: Math.ceil(Math.random() * 5),
        sign1: signs[Math.ceil(Math.random() * signs.length) - 1],
        sign2: signs[Math.ceil(Math.random() * signs.length) - 1],
        response: "",
        message: "Correct",
        incorrect: false
      });
    } else {
      setData({
        ...data,
        score: data.score === 0 ? data.score : data.score - 1,
        message: "Incorrect",
        incorrect: true
      });
    }
  }
};

export default useGenerator;
