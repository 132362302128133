/* eslint-disable no-unused-vars */
import { MathJax, MathJaxContext } from "better-react-mathjax";
import React from "react";
import useTimeout from "./useTimeout";
import useGenerator from "./useGenerator";
import Win from "./win";
import { grading, time } from "./common";
const { useState } = require("react");

let signs = ["+", "-", "*", "/"];

const Medium = () => {
  //* Data
  const [data, setData] = useState({
    num1: Math.ceil(Math.random() * 50),
    num2: Math.ceil(Math.random() * 50),
    sign: signs[Math.ceil(Math.random() * signs.length) - 1],
    score: 0,
    incorrect: null,
    response: "",
    message: ""
  });

  let equation;
  const generateEquation = () => {
    if (data.sign === "/") {
      equation = `\\frac{${data.num1}}{${data.num2}}`;
    } else if (data.sign === "*") {
      equation = `${data.num1}\\times${data.num2}`;
    } else {
      equation = `${data.num1} ${data.sign} ${data.num2}`;
    }
    return equation;
  };

  //* TimeOut System
  const allowed = useTimeout(time);

  //! Functions

  //* On Change Function
  const inputChange = (event) => {
    setData({
      ...data,
      response: event.target.value
    });
  };

  //* On Submit Function
  const onSubmit = (event) => {
    let answer = parseFloat(data.response);

    if (event.type === "click" || event.key === "Enter") {
      useGenerator(answer, signs, data, setData, false);
    }
  };

  //* Document
  return (
    <div className="medium">
      <h2>Medium difficulty</h2>
      {!allowed && (
        <div className="questions">
          <div className="block problem">
            <MathJaxContext>
              <MathJax
                renderMode="pre"
                typesettingOptions={{ fn: "tex2chtml" }}
                text={`\\LARGE{ ` + generateEquation() + `}`}
                inline
                dynamic
              />
            </MathJaxContext>
          </div>

          <div className="field">
            <p>Round the number to the nearest decimal point</p>
            <div className="box in-box block">
              {data.message !== "" && (
                <div
                  className={`box ${
                    data.incorrect !== null
                      ? data.incorrect
                        ? grading[0]
                        : grading[1]
                      : " "
                  }`}
                >
                  {data.message}
                </div>
              )}
              <p className="control has-icons-left">
                <input
                  type="text"
                  className="input is-primary in"
                  onChange={inputChange}
                  onKeyPress={onSubmit}
                  value={data.response}
                />

                <span className="icon is-small is-left in-icon">
                  <i className="fa fa-solid fa-question" />
                </span>
              </p>

              <button
                className="button is-rounded"
                id="submit"
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="score">Score: {data.score}</div>
        </div>
      )}

      {allowed && <Win data={data} />}
    </div>
  );
};

export default Medium;
