import React from "react";
let link = ["thumbs-up.gif", "noice.gif", "good-job.gif"];
let easters = ["thumbs-up-easter.gif"];
let random = Math.random();

const Win = (props) => {
  const data = props.data;
  const randomGenerator = (array) => {
    return array[Math.ceil(Math.random() * array.length) - 1];
  };

  var gif;

  if (random > 0.99) {
    gif = randomGenerator(easters);
  } else {
    gif = randomGenerator(link);
  }

  gif = `/images/${gif}`;

  return (
    <div className="win">
      <p>Total score: {!data.score ? 0 : data.score}!</p>
      <img className="win-img" src={gif} alt={gif} />
      <div className="buttons is-centered block">
        <button
          className="button is-rounded"
          onClick={() => window.location.reload(false)}
        >
          Retry
        </button>
      </div>
        
      
    </div>
  );
};

export default Win;
