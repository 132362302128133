import { useNavigate } from "react-router-dom";
import React from "react";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      <h2>Choose Difficulty</h2>

      <div className="field has-addons has-addons-centered">
        <p className="control">
          <button
            className="button is-rounded is-medium but-sec"
            onClick={() => navigate("/easy")}
          >
            Easy
          </button>
        </p>
        <p className="control">
          <button
            className="button is-rounded is-medium but-sec"
            onClick={() => navigate("/medium")}
          >
            Medium
          </button>
        </p>
        <p className="control">
          <button
            className="button is-rounded is-medium but-sec"
            onClick={() => navigate("/hard")}
          >
            Hard
          </button>
        </p>
      </div>
    </div>
  );
};

export default Home;
