import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import Easy from "./Easy";
import Medium from "./Medium";
import Hard from "./Hard";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            {/* Home Component */}
            <Route exact path="/" element={<Home />} />

            {/* Easy Difficulty component */}
            <Route exact path="/easy" element={<Easy />} />

            {/* Medium Difficulty component */}
            <Route exact path="/medium" element={<Medium />} />

            {/* Hard Difficulty component */}
            <Route exact path="/hard" element={<Hard />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
