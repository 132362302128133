/* eslint-disable no-lone-blocks */
import React from "react";

import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav>
      <div
        className="navbar is-black"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-start nav-color">
          <h1>Math</h1>
        </div>

        <div className="links">
          <div className="navbar-end">
            <Link to="/" className="navbar-item link">
              <span>Home</span>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

{
  /* <nav>
<div className="navbar" role="navigation" aria-label="main navigation">
  <div className="navbar-start nav-color">
    <h1>Math</h1>
  </div>

  <div className="links">
    <div className="navbar-end nav-color">
      <Link to="/" className="navbar-item">
        Home
      </Link>
    </div>
  </div>
</div>
</nav> */
}
